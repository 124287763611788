/*!
 * Bootstrap Grid v4.1.2 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/*@media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }*/
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.3333333333%;
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.6666666667%;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.3333333333%;
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.6666666667%;
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.3333333333%;
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.6666666667%;
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
  background-color: transparent;
  opacity: 1;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  background-color: #50dcff;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  width: 4px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  right: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  background-color: #50dcff;
  width: 11px;
  width: 6px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

/*   DIN   */
@font-face {
  font-family: 'Raleway';
  src: url("fonts/Raleway-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("fonts/Raleway-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("fonts/Raleway-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("fonts/Raleway-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("fonts/Raleway-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("fonts/Raleway-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-family: "Raleway";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

a {
  text-decoration: none;
  font-weight: 500;
  color: #50dcff;
  display: inline-block;
  cursor: pointer;
  transition: color .5s;
}

a:focus, a:active {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #286e96;
}

ul {
  list-style: none;
}

button {
  font-weight: 500;
  font-size: 18px;
  line-height: 60px;
  border: 3px solid #50dcff;
  color: #50dcff;
  display: inline-block;
  text-transform: uppercase;
  padding: 0 27px;
  transition: all .5s;
  outline: none;
  background: initial;
  cursor: pointer;
}

button:hover {
  color: #ffffff;
  background: #50dcff;
}

button.has-border {
  color: #ffffff;
  border: 3px solid #ffffff;
  background: #283278;
  position: relative;
}

button.has-border:before {
  content: "";
  position: absolute;
  top: -17px;
  bottom: -17px;
  left: -16px;
  right: -16px;
  border-left: 13px solid #283278;
  border-right: 13px solid #283278;
  border-top: 14px solid #283278;
  border-bottom: 14px solid #283278;
}

button.has-border:hover {
  background: #ffffff;
  color: #283278;
}

p {
  color: #283278;
}

p.italic {
  font-style: italic;
}

p.center {
  text-align: center;
}

.dot {
  display: inline-block;
  height: 12px;
  width: 12px;
  border: 1px solid #283278;
  border-radius: 50%;
  transition: background .5s;
  cursor: pointer;
}

.dot:hover, .dot.active {
  background: #283278;
}

h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  color: #283278;
  padding-bottom: 15px;
  position: relative;
}

h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 30px;
  background: #286e96;
  border-radius: 5px;
}

h2.center {
  text-align: center;
}

h2.center:after {
  right: 0;
  margin: auto;
}

h2:first-letter {
  text-transform: uppercase;
}

h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #283278;
  text-transform: uppercase;
}

h4 {
  font-size: 18px;
  line-height: 36px;
  color: #283278;
  font-weight: 400;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

svg {
  width: 100%;
  height: 100%;
}

section {
  padding: 50px 0;
}

section.has-bg {
  background: rgba(40, 110, 150, 0.1);
}

section.default h2 {
  margin-bottom: 50px;
}

.wp-video {
  width: 100% !important;
  height: auto !important;
}

body.hidden, html.hidden {
  overflow: hidden;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 !important;
}

#nav {
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

#nav .menu-contact {
  text-align: right;
  padding: 15px 15px 0;
  max-height: 100px;
  overflow: hidden;
  transition: all .5s;
}

#nav .menu-contact .inner-contact {
  display: inline-block;
  position: relative;
  padding-left: 30px;
}

#nav .menu-contact .inner-contact a {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 40px;
  color: #31619a;
  padding-left: 10px;
}

#nav .menu-contact .inner-contact a:hover {
  color: #50dcff;
}

#nav .menu-contact .inner-contact .logo {
  width: 28px;
  height: 28px;
  left: 0;
  bottom: 15px;
  right: auto;
  margin: auto;
  min-width: 0;
}

#nav .menu-contact .inner-contact .logo .wright-svg {
  position: absolute;
  left: 0;
}

#nav .menu-contact .inner-contact .logo .wright-svg, #nav .menu-contact .inner-contact .logo .st0 {
  fill: #31619a !important;
  stroke: #31619a !important;
  width: 28px;
  height: 28px;
}

#nav .inner-content {
  height: 100%;
  max-width: 1440px;
  padding: 0 15px;
  margin: auto;
}

#nav .inner-content .btn-close {
  display: none;
}

@media screen and (max-width: 1440px) {
  #nav .inner-content {
    max-width: 100%;
  }
}

#nav .inner-content .row {
  height: 100%;
}

#nav .menu {
  text-align: right;
  position: relative;
  padding: 0 15px;
  overflow: hidden;
}

#nav .menu .menu-contact {
  display: none;
}

#nav .menu > ul {
  display: inline-block;
  padding: 5px 0 0;
  position: relative;
  transition: padding .5s;
  display: block;
  overflow: hidden;
}

#nav .menu > ul li {
  display: inline-block;
  position: relative;
  padding: 15px 0 25px 38px;
}

#nav .menu > ul li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: -10px;
  height: 1px;
  border-bottom: 1px solid #31619a;
  opacity: 1;
  transition: .5s;
}

@media screen and (max-width: 767px) {
  #nav .menu > ul li:before {
    opacity: 0;
  }
}

#nav .menu > ul li a {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #283278;
}

#nav .menu > ul li:after {
  content: "";
  position: absolute;
  bottom: 18px;
  left: 35px;
  width: 0;
  border-bottom: 3px solid #286e96;
  transition: width .5s;
}

#nav .menu > ul li:first-of-type {
  padding-left: 0;
}

#nav .menu > ul li:first-of-type:after {
  left: 0;
}

#nav .menu > ul li:hover:after, #nav .menu > ul li.active:after {
  width: 20px;
}

#nav .menu > ul li:hover .sous-menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

#nav .menu > ul li.lang a {
  position: relative;
  float: left;
}

#nav .menu > ul li.lang a:first-of-type {
  padding-left: 15px;
}

#nav .menu > ul li.lang a:first-of-type:after {
  content: "";
  position: absolute;
  left: -14px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 15px;
  border: none;
  width: 1px;
  background: #283278;
}

#nav .menu > ul li.lang a:last-of-type {
  padding-left: 14px;
  position: relative;
}

#nav .menu > ul li.lang a:last-of-type:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 0;
  bottom: 0;
  height: 15px;
  width: 1px;
  background: #283278;
  margin: auto;
}

#nav .menu > ul li.lang a.active, #nav .menu > ul li.lang a:hover {
  color: #286e96;
  transition: color .5s;
}

#nav .menu > ul li.lang:after {
  content: "";
  display: block;
  clear: both;
  opacity: 0;
}

#nav .menu > ul:after {
  content: "";
  display: block;
  clear: both;
}

#nav .menu .logo-contact {
  position: absolute;
  right: 0;
  top: 5px;
  bottom: 5px;
  overflow: hidden;
  display: block;
  width: 16.66667%;
  transform: translateX(100%);
  transition: transform .5s;
}

#nav .menu .logo-contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}

#nav .menu .logo-contact a svg {
  max-width: 100%;
  width: auto;
  height: 60px;
}

#nav .menu .logo-contact a svg .st0 {
  stroke: transparent;
}

#nav .logo {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 30px;
  right: auto;
  height: 70px;
}

#nav .logo a {
  height: 100%;
  position: relative;
}

#nav .logo .wright-svg {
  width: auto;
  height: 65px;
  max-width: 230px;
  transition: all .3s .2s;
}

#nav .logo .wright-svg.has-scroll {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  height: 55px;
  transition: all .3s;
}

#nav .sous-menu {
  position: fixed;
  top: 129px;
  background: #172789;
  left: 0;
  right: 0;
  text-align: left;
  max-height: 100vh;
  max-height: calc(100vh - 74px);
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  transition: all .5s;
}

#nav .sous-menu .container {
  padding: 60px 15px;
}

#nav .sous-menu .container .col-md-4 {
  max-width: 360px;
}

#nav .sous-menu .container .col-md-3 {
  max-width: 240px;
}

#nav .sous-menu .container .item .item-content {
  display: none;
}

#nav .sous-menu .container .item h5 {
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: #ffffff;
  padding-bottom: 10px;
  margin-bottom: 25px;
  position: relative;
}

#nav .sous-menu .container .item h5:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 23px;
  border-bottom: 4px solid #50dcff;
  border-radius: 5px;
}

#nav .sous-menu .container .item h5.active {
  color: #50dcff;
}

#nav .sous-menu .container .item a {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  display: block;
  margin-bottom: 20px;
}

#nav .sous-menu .container .item a:hover, #nav .sous-menu .container .item a.active {
  color: #50dcff;
}

#nav .sous-menu .container .item a:last-of-type {
  margin: 0;
}

#nav .sous-menu .container .item:not(:first-of-type) {
  margin-top: 55px;
}

@media screen and (max-width: 1250px) {
  #nav .sous-menu {
    position: relative;
    top: 0;
    opacity: 1;
    visibility: visible;
    max-height: 0;
    overflow: hidden;
    overflow-y: auto;
  }
  #nav .sous-menu .container {
    max-width: 100%;
    padding: 15px 0 0;
  }
  #nav .sous-menu .container .col-md-4, #nav .sous-menu .container .col-md-3 {
    max-width: 100%;
    margin: 20px 0 0;
    flex: none;
  }
  #nav .sous-menu .container .col-md-4:first-of-type {
    margin-top: 0;
  }
  #nav .sous-menu .container .item {
    padding: 0 15px;
    transition: all .3s;
  }
  #nav .sous-menu .container .item:not(:first-of-type) {
    margin-top: 20px;
  }
  #nav .sous-menu .container .item h5 {
    padding-bottom: 0;
    padding-left: 30px;
    margin-bottom: 0;
    cursor: pointer;
  }
  #nav .sous-menu .container .item h5:after {
    top: 0;
    margin: auto;
  }
  #nav .sous-menu .container .item > h5 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
  }
  #nav .sous-menu .container .item > a {
    display: none;
  }
  #nav .sous-menu .container .item a {
    padding-left: 30px;
  }
  #nav .sous-menu .container .item .item-content {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #172789;
    bottom: 0;
    max-height: 100vh;
    max-height: calc(100vh - 77px);
    overflow: hidden;
    z-index: 1;
    padding: 30px 15px;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
  }
  #nav .sous-menu .container .item .item-content h5 {
    text-align: center;
    text-transform: uppercase;
    padding: 0 30px 10px;
    margin-bottom: 35px;
  }
  #nav .sous-menu .container .item .item-content h5:after {
    bottom: 0;
    right: 0;
    top: auto;
  }
  #nav .sous-menu .container .item .item-content .btn-back {
    position: absolute;
    width: 40px;
    height: 38px;
    top: 25px;
    left: 15px;
    cursor: pointer;
  }
  #nav .sous-menu .container .item .item-content .btn-back:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 15px;
    height: 15px;
    border-left: 3px solid #ffffff;
    border-bottom: 3px solid #ffffff;
    transform: rotate(45deg);
  }
  #nav .sous-menu .container .item .item-content a {
    padding: 0;
  }
  #nav .sous-menu .container .item .item-content.open {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (max-width: 1250px) {
  #nav {
    height: 77px;
  }
  #nav .inner-content .logo .wright-svg {
    opacity: 0;
    visibility: hidden;
  }
  #nav .inner-content .logo .wright-svg.has-scroll {
    visibility: visible;
    opacity: 1;
  }
  #nav .inner-content .menu-contact {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
    display: none;
    padding: 5px 0;
  }
  #nav .inner-content .menu-contact:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    border-bottom: 1px solid #31619a;
    opacity: 1;
    -webkit-transition: .5s;
    transition: .5s;
  }
  #nav .inner-content .menu-contact .inner-contact .logo {
    bottom: 0;
  }
  #nav .inner-content .menu-contact .inner-contact a {
    color: #ffffff;
  }
  #nav .inner-content .menu-contact .inner-contact .logo {
    bottom: 0;
  }
  #nav .inner-content .menu-contact .inner-contact .logo .wright-svg {
    opacity: 1;
    visibility: visible;
  }
  #nav .inner-content .menu-contact .inner-contact .logo .st0 {
    fill: #ffffff !important;
    stroke: #ffffff !important;
  }
  #nav .inner-content .menu-content {
    position: fixed;
    top: 77px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 0;
    max-width: none;
    text-align: left;
    background: #172789;
    height: auto;
    margin: 0;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
    overflow: hidden;
    overflow-y: auto;
  }
  #nav .inner-content .menu-content .menu {
    text-align: left;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 30px 15px 70px;
    min-height: 100%;
  }
  #nav .inner-content .menu-content .menu.nav3-open {
    overflow: hidden;
    max-height: 100%;
  }
  #nav .inner-content .menu-content .menu ul {
    display: block;
    padding: 0 15px;
  }
  #nav .inner-content .menu-content .menu ul li {
    display: block;
    float: none;
    padding: 0 0 20px;
  }
  #nav .inner-content .menu-content .menu ul li a {
    color: #ffffff;
  }
  #nav .inner-content .menu-content .menu ul li:after {
    top: 33px;
    bottom: auto;
  }
  #nav .inner-content .menu-content .menu ul li:first-of-type {
    padding-left: 0;
  }
  #nav .inner-content .menu-content .menu ul li:after {
    left: 0;
    border-bottom-color: #50dcff;
  }
  #nav .inner-content .menu-content .menu ul li.lang a {
    float: none;
  }
  #nav .inner-content .menu-content .menu ul li.lang a:first-of-type {
    padding: 0;
  }
  #nav .inner-content .menu-content .menu ul li.lang a:after {
    background: transparent;
  }
  #nav .inner-content .menu-content .menu ul li.lang a:not(:first-of-type):after {
    background: #50dcff;
  }
  #nav .inner-content .menu-content .menu ul li.lang a:hover, #nav .inner-content .menu-content .menu ul li.lang a.active {
    color: #50dcff;
  }
  #nav .inner-content .menu-content .menu ul li .sous-menu {
    transform: none;
  }
  #nav .inner-content .menu-content .menu ul li:hover .sous-menu {
    max-height: 1000px;
  }
  #nav .inner-content .menu-content .menu > ul:before {
    display: none;
  }
  #nav .inner-content .menu-content .menu .logo-contact {
    display: none;
  }
  #nav .inner-content .menu-content .menu .menu-contact {
    display: block;
  }
  #nav .inner-content .btn-close {
    display: block;
    position: absolute;
    top: 50%;
    right: 30px;
    width: 35px;
    height: 35px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  #nav .inner-content .btn-close span {
    position: absolute;
    top: 50%;
    right: 5px;
    left: 5px;
    border-bottom: 3px solid #283278;
    transform: translateY(-50%);
    cursor: pointer;
    transition: border-bottom-color 0s .2s;
  }
  #nav .inner-content .btn-close span:before, #nav .inner-content .btn-close span:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 3px solid #283278;
    transition: top .2s .2s, transform .2s;
  }
  #nav .inner-content .btn-close span:before {
    top: -8px;
  }
  #nav .inner-content .btn-close span:after {
    top: 8px;
  }
}

@media screen and (max-width: 1250px) and (max-width: 767px) {
  #nav .inner-content .btn-close {
    right: 15px;
  }
}

@media screen and (max-width: 1250px) {
  #nav.menu-open .btn-close span {
    border-bottom-color: transparent;
  }
  #nav.menu-open .btn-close span::before, #nav.menu-open .btn-close span::after {
    top: 0;
    transition: top .2s, transform .2s .2s;
  }
  #nav.menu-open .btn-close span::before {
    transform: rotate(45deg);
  }
  #nav.menu-open .btn-close span::after {
    transform: rotate(-45deg);
  }
  #nav.menu-open .menu-content {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 767px) {
  #nav .logo {
    left: 15px;
  }
}

#nav.fixed .menu-contact {
  max-height: 0;
  padding: 0 15px;
}

#nav.fixed .menu > ul {
  padding-right: 16.66667%;
}

#nav.fixed .menu > ul li:before {
  opacity: 0;
}

#nav.fixed .menu .logo-contact {
  transform: none;
}

#nav.fixed .sous-menu {
  top: 75px;
}

#nav.fixed .logo .wright-svg {
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
}

#nav.fixed .logo .wright-svg.has-scroll {
  opacity: 1;
  visibility: visible;
  transition: all .3s .2s;
}

.admin-bar #nav {
  top: 32px;
}

.admin-bar #nav .sous-menu {
  top: 160px;
}

@media screen and (max-width: 1250px) {
  .admin-bar #nav .sous-menu {
    top: 0;
  }
}

.admin-bar #nav.fixed .sous-menu {
  top: 105px;
}

@media screen and (max-width: 1250px) {
  .admin-bar #nav.fixed .sous-menu {
    top: 0;
  }
}

@media screen and (max-width: 1250px) {
  .admin-bar #nav .inner-content .menu-content {
    top: 108px;
  }
  .admin-bar #nav.fixed .inner-content .menu-content {
    top: 77px;
  }
}

@media screen and (max-width: 782px) {
  .admin-bar #nav {
    top: 46px;
  }
  .admin-bar #nav .inner-content .menu-content {
    top: 122px;
  }
  .admin-bar #nav.fixed {
    top: 0;
  }
  .admin-bar #nav.fixed .inner-content .menu-content {
    top: 77px;
  }
}

#header {
  margin-top: 130px;
  min-height: 510px;
  position: relative;
}

#header h1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 55px;
  max-width: 600px;
  margin-bottom: 45px;
  color: #ffffff;
}

#header h1 strong {
  color: #50dcff;
  font-weight: 500;
  font-size: 36px;
  line-height: 55px;
}

#header h1:after {
  display: none;
}

@media screen and (max-width: 599px) {
  #header > h1 {
    padding: 0 15px;
  }
}

#header a {
  font-weight: 500;
  font-size: 18px;
  line-height: 60px;
  border: 3px solid #50dcff;
  color: #50dcff;
  display: inline-block;
  text-transform: uppercase;
  padding: 0 27px;
  transition: all .5s;
  outline: none;
  background: initial;
  cursor: pointer;
  min-width: 209px;
}

#header a:hover {
  color: #ffffff;
  background: #50dcff;
}

#header .item {
  min-height: 510px;
  padding-top: 155px;
  position: relative;
  background-position: center 60%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  z-index: 0;
}

#header .item .inner-content {
  opacity: 0;
  transition: opacity .5s .5s;
}

@media screen and (max-width: 1250px) {
  #header .item {
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  #header .item {
    padding-bottom: 100px;
    background-position: center;
  }
  #header .item h1 div {
    display: inline-block;
  }
}

#header.owl-carousel .owl-stage {
  transition-duration: .5s !important;
}

#header.owl-carousel .owl-item.active .inner-content {
  opacity: 1;
}

#header.owl-carousel .owl-dots {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 45px;
  text-align: center;
}

#header.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  height: 14px;
  width: 14px;
  border: 1px solid #ffffff;
  margin: 0 7px;
  border-radius: 50%;
  transition: background .5s;
  cursor: pointer;
}

#header.owl-carousel .owl-dots .owl-dot:hover, #header.owl-carousel .owl-dots .owl-dot.active {
  background: #ffffff;
}

#header.owl-carousel .owl-dots .owl-dot span {
  display: none;
}

@media screen and (max-width: 1250px) {
  #header {
    margin-top: 76px;
  }
}

body.seconder #header {
  margin-top: 130px;
  min-height: 195px;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

body.seconder #header .content {
  text-align: center;
  margin: auto;
  padding: 0 15px;
}

body.seconder #header .content p {
  color: #ffffff;
}

body.seconder #header h1 {
  text-align: center;
  margin: auto;
}

@media screen and (max-width: 1250px) {
  body.seconder #header {
    margin-top: 76px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body.seconder #header {
    padding: 80px 0;
  }
}

.about h2 {
  margin-bottom: 35px;
}

.about.right .text {
  padding-right: 0;
  padding-left: 8.33333%;
}

.about .text > * {
  max-width: 550px;
}

.about .slider {
  padding-left: 75px;
  padding-top: 57px;
}

.about .slider .item .block-img {
  position: relative;
  margin-bottom: 15px;
  display: flex;
}

.about .slider .item .block-img .img {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
}

.about .slider .item .block-img .img img {
  max-width: 80%;
  margin: auto;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .about .slider .item .block-img .img {
    position: relative;
  }
  .about .slider .item .block-img .img img {
    width: 100%;
  }
}

@supports (-ms-ime-align: auto) {
  .about .slider .item .block-img .img {
    position: relative;
  }
  .about .slider .item .block-img .img img {
    width: 100%;
  }
}

.about .slider .item .block-img .inner-text {
  width: 90%;
  margin-left: auto;
  position: relative;
  padding: 35px 0 60px;
  min-height: 350px;
}

.about .slider .item .block-img .inner-text:before {
  content: "";
  shape-outside: url("../images/svg/about.svg");
  height: 100%;
  width: 85px;
  float: left;
}

.about .slider .item .block-img .inner-text p {
  position: relative;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .about .slider .item .block-img .inner-text {
    height: auto !important;
    text-align: right;
    padding-right: 5px;
  }
  .about .slider .item .block-img .inner-text:before {
    display: none;
  }
}

@supports (-ms-ime-align: auto) {
  .about .slider .item .block-img .inner-text {
    height: auto !important;
    text-align: right;
    padding-right: 5px;
  }
  .about .slider .item .block-img .inner-text:before {
    display: none;
  }
}

.about .slider .item .block-img:after {
  content: "";
  position: absolute;
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #286e96;
}

.about .slider .item .block-info {
  text-align: center;
}

.about .slider .item .block-info p {
  color: #283278;
  font-size: 18px;
  line-height: 36px;
}

.about .slider .owl-dots {
  text-align: center;
  padding-top: 5px;
}

.about .slider .owl-dots .owl-dot {
  display: inline-block;
  height: 12px;
  width: 12px;
  border: 1px solid #283278;
  margin: 0 7px;
  border-radius: 50%;
  transition: background .5s;
  cursor: pointer;
}

.about .slider .owl-dots .owl-dot:hover, .about .slider .owl-dots .owl-dot.active {
  background: #283278;
}

.about.mobile .text {
  max-width: 100%;
  flex: 1 1 100%;
}

.about.mobile .text p {
  max-width: 100%;
}

.about.mobile .slider {
  padding-top: 25px;
  padding-left: 15px;
  max-width: 100%;
  flex: 1 1 100%;
}

.about.mobile .slider .item .block-img {
  display: block;
}

.about.mobile .slider .item .block-img img {
  margin: auto;
  width: auto;
  padding-top: 25px;
}

.about.mobile .slider .item .block-img .inner-text {
  position: relative;
  max-width: none;
  text-align: left;
  padding: 25px 0;
  width: auto;
  min-height: 0;
}

.about.mobile .slider .item .block-img .inner-text p:nth-of-type(2) {
  max-width: none;
  text-align: left;
}

.about.mobile .slider .item .block-img .inner-text:before {
  display: none;
}

.box {
  background: #4d2685;
  overflow: hidden;
  position: relative;
  padding: 110px 15px 40px;
}

.box.has-img {
  height: 100%;
}

.box a {
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #ffffff;
  display: block;
  position: relative;
  padding-left: 40px;
}

.box a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  height: 4px;
  width: 23px;
  margin: auto;
  border-bottom: 4px solid #50dcff;
  border-radius: 5px;
}

.box a:hover {
  color: #50dcff;
}

.box .title {
  position: absolute;
  top: 110px;
  left: 15px;
  right: 15px;
  bottom: 40px;
  transition: all .5s;
  max-width: 240px;
  margin: auto;
}

.box .title a {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  padding: 0 0 5px;
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .box .title a {
    font-size: 22px;
    line-height: 32px;
  }
}

.box .title a:before {
  top: auto;
}

.box .detail {
  opacity: 0;
  padding: 0;
  padding-right: 5px;
  transform: translateY(120%);
  transition: all .5s;
  max-width: 240px;
  margin: auto;
}

.box .detail li {
  margin-bottom: 25px;
}

.box .detail li:last-of-type {
  margin: 0;
}

.box:hover .title {
  transform: translateY(-100%);
  opacity: 0;
}

.box:hover .detail {
  transform: translateY(0);
  opacity: 1;
}

.box.has-img {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.box.has-img a {
  padding: 0;
}

.box.has-img a:before {
  display: none;
}

.box.has-img .block-img {
  max-width: 250px;
  width: 100%;
  position: relative;
  text-align: center;
}

.box.has-img .block-img #picto_animation_white {
  opacity: .3;
}

.box.has-img .block-img #picto_animation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  height: auto;
}

.box.has-img .block-img #picto_animation path {
  stroke: #ffffff;
  stroke-dasharray: 130;
  stroke-dashoffset: 131;
  transition: all 1.7s;
}

@media screen and (min-width: 1099px) {
  .box.has-img .block-img #picto_animation_white, .box.has-img .block-img #picto_animation {
    min-width: 250px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .box.has-img .block-img #picto_animation_white, .box.has-img .block-img #picto_animation {
    width: 200px;
    height: 200px;
    margin: auto;
    transition: opacity 1s;
  }
  .box.has-img .block-img #picto_animation {
    opacity: 0;
  }
}

.box.has-img:hover .block-img #picto_animation path {
  stroke-dashoffset: 0 !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .box.has-img:hover .block-img #picto_animation {
    opacity: 1;
  }
}

@media screen and (max-width: 1024px) and (min-width: 992px) {
  .box {
    padding: 40px 15px;
  }
}

@media screen and (max-width: 767px) {
  .box {
    height: auto;
    margin-top: 10px;
    padding: 0 15px;
    max-height: 58px;
    overflow: hidden;
    transition: all .5s;
  }
  .box .title {
    position: relative;
    left: 0;
    right: 0;
    padding: 18px 10px 18px 0;
    max-width: none;
    top: 0;
    bottom: 0;
  }
  .box .title:after {
    content: "";
    width: 11px;
    height: 7px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    background: url(../images/svg/icon-expand-arrow.svg) no-repeat center;
    background-size: 100% 100%;
    transition: transform .5s;
  }
  .box .title a {
    font-size: 18px;
    line-height: 22px;
    padding: 0;
  }
  .box .title a:before {
    display: none;
  }
  .box .detail {
    max-height: none;
    max-width: none;
    padding-bottom: 10px;
    height: auto !important;
  }
  .box .detail li {
    padding: 12px 0;
    border-bottom: 1px solid #ffffff;
    margin: 0;
  }
  .box .detail li a {
    padding: 0;
  }
  .box .detail li a:before {
    display: none;
  }
  .box .detail li:last-of-type {
    border: none;
  }
  .box .detail .ps__rail-y {
    opacity: 0;
  }
  .box.has-img {
    max-height: none;
    padding: 15px;
  }
  .box:hover {
    max-height: 2000px;
  }
  .box:hover .title {
    transform: none;
    opacity: 1;
  }
}

.services {
  padding: 0 15px;
}

.services .col-lg-3 {
  padding: 0;
}

@media screen and (max-width: 767px) {
  .services .col-lg-3 {
    padding: 0 15px;
  }
}

@media screen and (max-width: 991px) {
  .services {
    margin: auto;
  }
}

.temoignages .inner-content {
  padding: 0 0 95px;
}

.temoignages .inner-content .block {
  position: relative;
  padding: 70px 65px;
}

.temoignages .inner-content .items {
  background: rgba(40, 110, 150, 0.1);
}

.temoignages .inner-content .item {
  padding: 45px 40px;
}

.temoignages .inner-content .item p {
  text-align: center;
  word-spacing: -1.5px;
}

.temoignages .inner-content .icon-left,
.temoignages .inner-content .icon-right {
  position: absolute;
  width: 100px;
}

.temoignages .inner-content .icon-left {
  left: 0;
  bottom: 20px;
}

.temoignages .inner-content .icon-right {
  right: 0;
  top: 20px;
}

.temoignages .inner-content .owl-nav {
  display: none;
}

.temoignages .inner-content .owl-dots {
  text-align: center;
  margin-top: 90px;
  position: absolute;
  left: 0;
  right: 0;
}

.temoignages .inner-content .owl-dots .owl-dot {
  display: inline-block;
  height: 19px;
  width: 19px;
  border: 1px solid #283278;
  margin: 0 7px;
  border-radius: 50%;
  transition: background .5s;
  cursor: pointer;
}

.temoignages .inner-content .owl-dots .owl-dot:hover, .temoignages .inner-content .owl-dots .owl-dot.active {
  background: #283278;
}

@media screen and (max-width: 530px) {
  .temoignages .inner-content .block {
    padding: 70px 35px;
  }
  .temoignages .inner-content .item {
    padding: 45px 20px;
  }
  .temoignages .inner-content .icon-left,
  .temoignages .inner-content .icon-right {
    width: 50px;
    height: 50px;
  }
  .temoignages .inner-content .icon-left {
    bottom: 30px;
  }
  .temoignages .inner-content .icon-right {
    top: 30px;
  }
}

@media screen and (max-width: 767px) {
  .temoignages .container {
    max-width: 100%;
  }
  .temoignages .container .inner-content {
    padding-bottom: 20px;
  }
  .temoignages .container .inner-content .block {
    padding: 50px 25px;
  }
  .temoignages .container .inner-content .block .icon-left, .temoignages .container .inner-content .block .icon-right {
    width: 60px;
  }
  .temoignages .container .inner-content .block .icon-left {
    bottom: 35px;
  }
  .temoignages .container .inner-content .block .icon-right {
    top: 35px;
  }
  .temoignages .container .inner-content .block .item {
    padding: 45px 25px;
  }
  .temoignages .container .inner-content .block .owl-dots {
    margin-top: 30px;
  }
  .temoignages .container .inner-content .block .owl-nav {
    display: block;
  }
  .temoignages .container .inner-content .block .owl-nav .owl-prev, .temoignages .container .inner-content .block .owl-nav .owl-next {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    margin: auto;
  }
  .temoignages .container .inner-content .block .owl-nav .owl-prev span, .temoignages .container .inner-content .block .owl-nav .owl-next span {
    display: none;
  }
  .temoignages .container .inner-content .block .owl-nav .owl-prev:after, .temoignages .container .inner-content .block .owl-nav .owl-next:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 2px solid #283278;
  }
  .temoignages .container .inner-content .block .owl-nav .owl-prev {
    left: -25px;
  }
  .temoignages .container .inner-content .block .owl-nav .owl-prev:after {
    border-left: 2px solid #283278;
    transform: rotate(45deg);
  }
  .temoignages .container .inner-content .block .owl-nav .owl-next {
    right: -25px;
  }
  .temoignages .container .inner-content .block .owl-nav .owl-next:after {
    border-right: 2px solid #283278;
    transform: rotate(-45deg);
  }
}

.partenaires {
  background: rgba(40, 110, 150, 0.1);
}

.partenaires .inner-content {
  padding: 70px 0 55px;
}

@media screen and (max-width: 767px) {
  .partenaires .inner-content:not(.owl-carousel) .item {
    text-align: center;
    padding: 25px 0;
  }
  .partenaires .inner-content:not(.owl-carousel) .item:first-of-type {
    padding-top: 0;
  }
  .partenaires .inner-content:not(.owl-carousel) .item:last-of-type {
    padding-bottom: 0;
  }
}

.partenaires .owl-carousel .owl-item .items {
  display: flex;
}

.partenaires .owl-carousel .owl-item .item {
  flex: 1 1 25%;
  max-width: 25%;
  text-align: center;
  padding: 0 15px;
}

.partenaires .owl-carousel .owl-item .item img {
  width: auto;
  height: 60px;
}

@media screen and (max-width: 889px) {
  .partenaires .owl-carousel .owl-item .item img {
    margin: auto;
  }
}

.block-contact {
  padding: 0;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .block-contact .container {
    max-width: none;
  }
}

.block-contact h2 {
  margin-bottom: 50px;
}

.block-contact .map {
  position: relative;
  min-height: 500px;
}

@media screen and (max-width: 767px) {
  .block-contact .map {
    min-height: 300px;
  }
}

.block-contact .map #map {
  position: absolute;
  top: 0;
  bottom: -15px;
  right: 0;
  margin-bottom: 0;
  width: 50vw;
}

.block-contact .map #map img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .block-contact .map #map {
    width: 100vw !important;
    max-height: 300px;
    overflow: hidden;
  }
}

.block-contact .map #map h2 {
  text-transform: none;
  margin-bottom: 15px;
}

.block-contact .map #map p {
  font-size: 16px;
  line-height: 25px;
}

.block-contact .info {
  padding: 50px 0;
  padding-left: 50px;
  display: flex;
  align-items: center;
}

.block-contact .info > div > * {
  margin-bottom: 30px;
}

.block-contact .info > div > *:last-of-type {
  margin: 0;
}

.block-contact .info > div > * a {
  color: inherit;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .block-contact .info {
    padding-left: 15px;
  }
}

.block-form h2 {
  margin-bottom: 50px;
}

.block-form form {
  max-width: 650px;
  margin: auto;
}

.block-form form .input {
  width: 50%;
  display: inline-block;
  float: left;
  padding-bottom: 10px;
  position: relative;
}

.block-form form .input:nth-child(even) {
  padding-right: 5px;
}

.block-form form .input:nth-child(odd) {
  padding-left: 5px;
}

.block-form form .input.full {
  width: 100%;
  padding: 0;
  max-width: 100%;
  height: 225px;
  padding: 0;
}

.block-form form .input.full > * {
  height: 100%;
}

.block-form form:after {
  content: "";
  display: block;
  clear: both;
}

.block-form form .input-btn {
  text-align: center;
  padding-top: 50px;
  clear: both;
}

.block-form form .input-btn button {
  min-width: 209px;
}

.block-form form div.wpcf7-mail-sent-ok {
  display: none !important;
}

@media screen and (max-width: 600px) {
  .block-form form {
    max-width: 650px;
  }
  .block-form form .input {
    width: 100%;
  }
  .block-form form .input:nth-child(odd) {
    padding-left: 0;
  }
  .block-form form .input:nth-child(even) {
    padding-right: 0;
  }
}

.block-form form .wpcf7-validation-errors {
  border: none;
  font-family: "Raleway";
  font-size: 16px;
  line-height: 25px;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

input, textarea {
  outline: none;
  border: none;
  font-size: 16px;
  line-height: 35px;
  width: 100%;
  background: #ffffff;
  padding: 10px 25px;
  resize: none;
  font-family: "Raleway";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  min-height: 55px;
  height: 100%;
}

input + *, textarea + * {
  display: none !important;
}

input.wpcf7-not-valid, textarea.wpcf7-not-valid {
  border-bottom: 2px solid red;
}

input::placeholder, textarea::placeholder {
  font-family: "Raleway";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.examens .category {
  width: 100%;
  text-align: center;
}

.examens .category a {
  display: inline-block;
  font-family: "Raleway";
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: #283278;
  position: relative;
  padding: 15px 18px 20px;
  cursor: pointer;
}

.examens .category a:after {
  content: "";
  position: absolute;
  bottom: 13px;
  left: 18px;
  width: 0;
  border-bottom: 3px solid #286e96;
  transition: width .5s;
}

.examens .category a:hover:after, .examens .category a.active:after {
  width: 20px;
}

.examens .btn_backTop {
  background: #286e96;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-radius: 50px;
  transform: translateY(50px);
  opacity: 0;
  visibility: hidden;
  transition: all .5s;
  cursor: pointer;
}

.examens .btn_backTop:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 45%;
  height: 45%;
  margin: auto;
  border-top: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
  transform: translateY(25%) rotate(-45deg);
  transform-origin: center;
}

.examens .btn_backTop.show {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.examens .btn_backTop:hover {
  background: #4d2685;
}

.block-img-text {
  padding: 50px 0 60px;
}

.block-img-text.title-team {
  padding-bottom: 0;
}

.block-img-text.title-team h2 {
  margin-bottom: 0;
}

.block-img-text h4 {
  line-height: 18px;
}

.block-img-text p:first-of-type {
  padding: 50px 0 0;
}

.block-img-text p:last-of-type {
  padding-bottom: 35px;
}

.block-img-text h2 {
  margin-bottom: 50px;
}

.block-img-text .img {
  text-align: right;
}

.block-img-text .img img {
  max-width: 300px;
  margin: auto;
}

.block-img-text .img img:not(:nth-of-type(1)) {
  margin-top: 40px;
}

.block-img-text.modele-images-left .img {
  text-align: left;
}

@media screen and (max-width: 767px) {
  .block-img-text .img, .block-img-text.modele-images-left .img {
    text-align: center;
  }
  .block-img-text .img {
    order: 1;
    padding-bottom: 30px;
  }
  .block-img-text .text {
    order: 2;
  }
}

.block-slider {
  padding: 50px 0 60px;
}

.block-slider .inner-content {
  margin-top: 60px;
  position: relative;
  width: 100%;
}

.block-slider .inner-content .col-md-9 {
  padding: 0 9px;
}

@media screen and (max-width: 991px) {
  .block-slider .inner-content .col-md-9 {
    margin: 0;
    max-width: 100%;
  }
}

.block-slider .inner-content .items .owl-item {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50px);
  transition: all .25s;
}

.block-slider .inner-content .items .owl-item.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.block-slider .inner-content .items .item {
  width: 100%;
  position: relative;
}

.block-slider .inner-content .items .item img {
  position: relative;
  width: 100%;
  float: left;
}

.block-slider .inner-content .items .item:after {
  content: "";
  display: block;
  clear: both;
}

.block-slider .inner-content .info {
  position: absolute;
  left: 0;
  max-width: 300px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: rgba(40, 110, 150, 0.5);
  padding: 25px 35px;
}

.block-slider .inner-content .info p {
  color: #ffffff;
}

@media screen and (max-width: 991px) {
  .block-slider .inner-content .info {
    position: relative;
    margin-top: -100px;
    max-width: none;
    transform: none;
    top: 0;
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  .block-slider .container .row {
    margin: 0;
  }
  .block-slider .container .row .inner-content .col-md-9 {
    padding: 0;
  }
  .block-slider .container .row .inner-content .info {
    margin-left: 15px;
    margin-right: 15px;
    padding: 15px;
  }
}

.block-services h2 {
  margin-bottom: 50px;
}

.block-services .service {
  padding: 7px;
}

.block-services .service .content {
  padding: 20px 30px 125px 40px;
  height: 100%;
}

.block-services .service .content.voilet {
  background: #283278;
}

.block-services .service .content.voilet .has-border:hover {
  color: #283278;
}

.block-services .service .content.voilet-bg {
  background: #4d2685;
}

.block-services .service .content.voilet-bg .has-border:hover {
  color: #4d2685;
}

.block-services .service .content.blue {
  background: #286e96;
}

.block-services .service .content.blue .has-border:hover {
  color: #286e96;
}

.block-services .service .content h2 {
  color: #ffffff;
  font-size: 28px;
  line-height: 44px;
  padding-bottom: 2px;
  display: block;
  margin: 0 15px 30px;
}

.block-services .service .content h2:after {
  background: #50dcff;
}

.block-services .service .content p {
  color: #ffffff;
}

.block-services .service .content .title {
  text-align: center;
}

.block-services .service .content a {
  font-weight: 500;
  font-size: 18px;
  line-height: 60px;
  border: 3px solid #50dcff;
  color: #50dcff;
  display: inline-block;
  text-transform: uppercase;
  padding: 0 27px;
  transition: all .5s;
  outline: none;
  background: initial;
  cursor: pointer;
  color: #ffffff;
  border: 3px solid #ffffff;
  background: #283278;
  position: relative;
  position: absolute;
  bottom: 40px;
  text-align: center;
  background: transparent;
}

.block-services .service .content a:before {
  content: "";
  position: absolute;
  top: -17px;
  bottom: -17px;
  left: -16px;
  right: -16px;
  border-left: 13px solid #283278;
  border-right: 13px solid #283278;
  border-top: 14px solid #283278;
  border-bottom: 14px solid #283278;
}

.block-services .service .content a:hover {
  background: #ffffff;
  color: #283278;
}

.block-services .service .content a:before {
  display: none;
}

.block-services .service .content a:hover {
  background: #ffffff;
}

@media screen and (max-width: 1199px) {
  .block-services .services {
    padding: 0;
    margin: 0;
    max-width: 100%;
    flex: 1 1 100%;
  }
}

@media screen and (max-width: 991px) {
  .block-services .service {
    padding: 7px 0;
  }
  .block-services .service .content {
    padding: 20px 15px 125px;
  }
  .block-services .service .content h2 {
    margin: 0 0 30px;
  }
}

.multi-image {
  overflow: hidden;
}

.multi-image h2 {
  margin-bottom: 60px;
}

.multi-image .block-content {
  padding-top: 50px;
}

.multi-image .block {
  width: 50%;
  position: relative;
  max-width: 800px;
  margin-bottom: 30px;
}

.multi-image .block:after {
  content: "";
  display: block;
  clear: both;
}

.multi-image .block .img {
  position: absolute;
  max-width: 800px;
}

.multi-image .block .img .img1 {
  max-width: 75%;
}

.multi-image.left .block {
  left: 0;
  padding-right: 40px;
  float: left;
}

.multi-image.left .block .img {
  right: 40px;
  padding-left: 40%;
}

.multi-image.left .block .img .img1 {
  position: absolute;
  left: 0;
  top: -50px;
  z-index: -1;
  right: 50%;
  max-width: 50%;
}

.multi-image.left .block .img .img2 {
  text-align: right;
  margin-left: auto;
}

.multi-image.right .block {
  right: 0;
  padding-left: 40px;
  float: right;
}

.multi-image.right .block .img {
  left: 40px;
  padding-right: 40%;
}

.multi-image.right .block .img .img1 {
  position: absolute;
  right: 0;
  top: -50px;
  z-index: -1;
  left: 50%;
  max-width: 50%;
}

.multi-image-slider h2 {
  margin-bottom: 50px;
}

.multi-image-slider .items {
  text-align: center;
  max-width: 800px;
  padding-right: 20px;
  float: right;
}

.multi-image-slider .items:after {
  content: "";
  display: block;
  clear: both;
}

.multi-image-slider .items:not(.owl-carousel) .item img {
  width: 100%;
}

.multi-image-slider.right .items {
  padding-right: 0;
  padding-left: 20px;
  float: none;
}

.multi-image-slider .owl-nav {
  top: -20px;
  display: inline-block;
  position: relative;
}

.multi-image-slider .owl-nav button.owl-prev, .multi-image-slider .owl-nav button.owl-next {
  padding: 20px !important;
  background: #283278;
  position: relative;
  margin-right: 1px;
  transition: background .5s;
}

.multi-image-slider .owl-nav button.owl-prev span, .multi-image-slider .owl-nav button.owl-next span {
  display: none;
}

.multi-image-slider .owl-nav button.owl-prev:after, .multi-image-slider .owl-nav button.owl-next:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 9px;
  height: 9px;
  top: 0;
  bottom: 0;
  margin: auto;
  transform-origin: center;
  transform: rotate(135deg);
  border-right: 3px solid #ffffff;
  border-bottom: 3px solid #ffffff;
}

.multi-image-slider .owl-nav button.owl-prev:hover, .multi-image-slider .owl-nav button.owl-next:hover {
  background: #286e96;
}

.multi-image-slider .owl-nav button.owl-next {
  margin: 0;
  margin-left: 1px;
}

.multi-image-slider .owl-nav button.owl-next:after {
  transform: rotate(-45deg);
}

.block-single-img-text h2 {
  margin-bottom: 50px;
}

.block-single-img-text .block-img {
  max-width: 800px;
}

@media screen and (min-width: 768px) {
  .block-single-img-text .block-img {
    padding-top: 10px;
  }
}

.block-single-img-text .block-img img {
  width: 100%;
}

.block-single-img-text.left .block-img {
  float: right;
  text-align: right;
}

.block-single-img-text.left .block-img:after {
  content: "";
  display: block;
  clear: both;
}

.block-article .filters {
  margin: 0 -10px 70px;
}

.block-article .filters > * {
  color: #286e96;
  margin-right: 24px;
  position: relative;
}

.block-article .filters > *:hover, .block-article .filters > *.active {
  color: #50dcff;
}

.block-article .filters > *:after {
  content: "|";
  position: absolute;
  top: 0;
  right: -12px;
  color: #283278;
}

.block-article .filters > *:last-of-type {
  margin: 0;
}

.block-article .filters > *:last-of-type:after {
  content: "";
}

@media screen and (max-width: 767px) {
  .block-article .filters {
    margin: 0 0 70px;
  }
}

.block-article .articles > div {
  padding: 0 5px;
}

@media screen and (max-width: 767px) {
  .block-article .articles > div {
    padding: 0 15px;
  }
  .block-article .articles > div:not(:first-of-type) {
    margin-top: 10px;
  }
}

.block-article .articles > div a {
  background: rgba(40, 110, 150, 0.1);
  display: block;
  height: 100%;
}

.block-article .articles > div .img {
  width: 100%;
}

.block-article .articles > div .img img {
  width: 100%;
}

.block-article .articles > div .content {
  /*background: $light;*/
  padding: 20px;
}

.block-article .articles > div .content h3 {
  text-transform: uppercase;
  padding-bottom: 15px;
  margin-bottom: 20px;
  position: relative;
}

.block-article .articles > div .content h3:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 30px;
  background: #286e96;
  border-radius: 5px;
}

.block-article .articles:not(:first-of-type) {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .block-article .articles:not(:first-of-type) {
    margin-top: 10px;
  }
}

.block-article .btn-more {
  margin-top: 50px;
}

.block-article .btn-more a {
  width: 43px;
  height: 43px;
  margin: 0 auto;
  background: rgba(40, 110, 150, 0.1);
  border-radius: 50px;
  display: block;
  position: relative;
  transition: background .3s;
}

.block-article .btn-more a:before, .block-article .btn-more a:after {
  content: "";
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #283278;
  transition: background .3s;
}

.block-article .btn-more a:before {
  width: 20px;
  height: 2px;
}

.block-article .btn-more a:after {
  height: 20px;
  width: 2px;
}

.block-article .btn-more a:hover {
  background: #283278;
}

.block-article .btn-more a:hover:before, .block-article .btn-more a:hover:after {
  background: #ffffff;
}

#footer {
  background: #283278;
}

#footer .block-contact {
  background: #172789;
  padding: 23px 0;
}

#footer .block-contact p {
  font-size: 26px;
  line-height: 34px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -0.3px;
  word-spacing: -2px;
}

#footer .block-contact p a {
  font-size: 26px;
  line-height: 33px;
  text-transform: uppercase;
  font-weight: 800;
}

#footer .inner-content {
  padding: 60px 0 95px;
}

#footer .inner-content h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  text-transform: uppercase;
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;
}

#footer .inner-content h5:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 4px;
  width: 24px;
  left: 0;
  border-bottom: 4px solid #50dcff;
  border-radius: 5px;
}

#footer .inner-content p {
  color: #ffffff;
}

#footer .inner-content p a {
  color: inherit;
  font-weight: 400;
}

#footer .inner-content p a:hover {
  color: #50dcff;
}

#footer .inner-content .contact {
  padding-top: 30px;
}

#footer .inner-content .menu a {
  color: #ffffff;
  position: relative;
  display: block;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 28px;
  margin-bottom: 25px;
}

#footer .inner-content .menu a:not(.has-logo):after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 14px;
  height: 2px;
  border-bottom: 2px solid #50dcff;
}

#footer .inner-content .menu a:hover {
  color: #50dcff;
}

#footer .inner-content .menu a.has-logo {
  margin: 107px 0 0;
  padding: 0;
}

#footer .inner-content .menu li:last-of-type a {
  margin: 0;
}

#footer .inner-content .block-adress {
  padding-left: 65px;
}

#footer .inner-content .block-menu {
  padding-left: 125px;
  position: relative;
}

#footer .inner-content .block-menu:after {
  content: "";
  position: absolute;
  left: 62px;
  width: 1px;
  border-left: 1px solid #50dcff;
  top: 0;
  bottom: 0;
}

#footer .copyright {
  padding-bottom: 35px;
}

#footer .copyright p {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 25px;
}

#footer .copyright p a {
  color: inherit;
  font-weight: 400;
}

@media screen and (max-width: 1200px) {
  #footer .inner-content .block-adress {
    padding-left: 30px;
  }
  #footer .inner-content .block-menu {
    padding-left: 60px;
  }
  #footer .inner-content .block-menu:after {
    left: 30px;
  }
}

@media screen and (max-width: 767px) {
  #footer .block-contact p.center {
    font-size: 20px;
    line-height: 25px;
  }
  #footer .block-contact p.center a {
    font-size: 20px;
    line-height: 25px;
  }
  #footer .inner-content .block-map {
    text-align: center;
  }
  #footer .inner-content .block-adress {
    padding-left: 15px;
    padding-top: 50px;
  }
  #footer .inner-content .block-menu {
    padding-left: 15px;
    padding-top: 50px;
  }
  #footer .inner-content .block-menu:after {
    display: none;
  }
  #footer .inner-content .block-menu .menu a.has-logo {
    text-align: center;
  }
}
