* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    font-family: $font;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

a {
    text-decoration: none;
    font-weight: 500;
    color: $blue2;
    display: inline-block;
    cursor: pointer;
    transition: color .5s;

    &:focus, &:active {
        color: inherit;
        text-decoration: none;
    }

    &:hover {
        color: $blue1;
    }
}

ul {
    list-style: none;
}

button {
    font-weight: 500;
    font-size: 18px;
    line-height: 60px;
    border: 3px solid $blue2;
    color: $blue2;
    display: inline-block;
    text-transform: uppercase;
    padding: 0 27px;
    transition: all .5s;
    outline: none;
    background: initial;
    cursor: pointer;

    &:hover {
        color: $white;
        background: $blue2;
    }

    &.has-border {
        color: $white;
        border: 3px solid $white;
        background: $voilet;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: -17px;
            bottom: -17px;
            left: -16px;
            right: -16px;
            border-left: 13px solid $voilet; 
            border-right: 13px solid $voilet; 
            border-top: 14px solid $voilet; 
            border-bottom: 14px solid $voilet; 
        }

        &:hover {
            background: $white;
            color: $voilet;
        }
    }
}

p {
    color: $voilet;

    &.italic {
        font-style: italic;
    }

    &.center {
        text-align: center;
    }
}

.dot {
    display: inline-block;
    height: 12px;
    width: 12px;
    border: 1px solid $voilet;
    border-radius: 50%;
    transition: background .5s;
    cursor: pointer;

    &:hover, &.active {
        background: $voilet;
    }
}

h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    color: $voilet;
    padding-bottom: 15px;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 30px;
        background: $blue1;
        border-radius: 5px;
    }

    &.center {
        text-align: center;

        &:after {
            right: 0;
            margin: auto;
        }
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: $voilet;
    text-transform: uppercase;
}

h4 {
    font-size: 18px;
    line-height: 36px;
    color: $voilet;
    font-weight: 400;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

svg {
    width: 100%;
    height: 100%;
}

section {
    padding: 50px 0;

    &.has-bg {
        background: $light;
    }

    &.default {
        h2 {
            margin-bottom: 50px;
        }
    }
}

.wp-video {
    width: 100% !important;
    height: auto !important;
}

body.hidden,html.hidden {
    overflow: hidden;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	margin: 0 !important;
}