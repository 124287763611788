.about {

    h2 {
        margin-bottom: 35px;
    }

    &.right {
        .text {
            padding-right: 0;
            padding-left: $width/12;
        }
    }

    .text {
        & > * {
            max-width: 550px;
        }
    }

    .slider {
        padding-left: 75px;
        padding-top: 57px;

        .item {
            .block-img {
                position: relative;
                margin-bottom: 15px;
                display: flex;

                .img {
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: bottom center;

                    img {
                        max-width: 80%;
                        margin: auto;
                    }

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        position: relative;

                        img {
                            width: 100%;
                        }
                    }

                    @supports (-ms-ime-align: auto) {
                        position: relative;

                        img {
                            width: 100%;
                        }
                    }
                }

                .inner-text {
                    width: 90%;
                    margin-left: auto;
                    position: relative;
                    padding: 35px 0 60px;
					min-height: 350px;

                    &:before {
                        content: "";
                        shape-outside: url("../images/svg/about.svg");
                        height: 100%;
                        width: 85px;
                        float: left;
                    }

                    p {
                        position: relative;
                    }

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        height: auto !important;
                        text-align: right;
						padding-right: 5px;

                        &:before {
                            display: none;
                        }
                    }

                    @supports (-ms-ime-align: auto) {
                        height: auto !important;
                        text-align: right;
						padding-right: 5px;

                        &:before {
                            display: none;
                        }
                    }
                }
                
                &:after {
                    content: "";
                    position: absolute; 
                    height: 3px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: $blue1;
                }
            }

            .block-info {
                text-align: center;

                p {
                    color: $voilet;
                    font-size: 18px;
                    line-height: 36px;
                }
            }
        }
        

        .owl-dots {
            text-align: center;
            padding-top: 5px;
            
            .owl-dot {
                display: inline-block;
                height: 12px;
                width: 12px;
                border: 1px solid $voilet;
                margin: 0 7px;
                border-radius: 50%;
                transition: background .5s;
                cursor: pointer;

                &:hover, &.active {
                    background: $voilet;
                }
            }
        }
    }

    &.mobile{
		.text {
			max-width: 100%;
            flex: 1 1 100%;
            
            p {
                max-width: 100%;
            }
		}
	
        .slider {
            padding-top: 25px;
            padding-left: 15px;
			max-width: 100%;
			flex: 1 1 100%;

            .item .block-img {
                display: block;

                img {
                    margin: auto;
                    width: auto;
                    padding-top: 25px;
                }
                .inner-text {
                    position: relative;
                    max-width: none;
                    text-align: left;
                    padding: 25px 0;
                    width: auto;
                    min-height: 0;
    
                    p:nth-of-type(2) {
                        max-width: none;
                        text-align: left;
                    }

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}