.box {
    
    background: $voilet-bg;
    overflow: hidden;
    position: relative;
    padding: 110px 15px 40px;

    &.has-img {
        height: 100%;
    }

    a {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        color: $white;
        display: block;
        position: relative;
        padding-left: 40px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            height: 4px;
            width: 23px;
            margin: auto;
            border-bottom: 4px solid $blue2;
            border-radius: 5px;
        }
        
        &:hover {
            color: $blue2;
        }
    }

    .title {
        position: absolute;
        top: 110px;
        left: 15px;
        right: 15px;
        bottom: 40px;
        transition: all .5s;
        max-width: 240px;
        margin: auto;

        a {
            font-weight: 600;
            font-size: 28px;
            line-height: 32px;
            padding: 0 0 5px;

            @media screen and (max-width: 1200px ) and (min-width: 992px){
                font-size: 22px;
                line-height: 32px;
            }

            &:before {
                top: auto;
            }
        }
    }

    .detail {
        opacity: 0;
        padding: 0;
        padding-right: 5px;
        transform: translateY(120%);
        transition: all .5s;
        max-width: 240px;
        margin: auto;

        li {
            margin-bottom: 25px;

            &:last-of-type {
                margin: 0;
            }
        }
    }

    &:hover {
        .title {
            transform: translateY(-100%);
            opacity: 0;
        }

        .detail {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &.has-img {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        a {
            padding: 0;

            &:before {
                display: none;
            }
        }

        .block-img {
            max-width: 250px;
            width: 100%;
            position: relative;
            text-align: center;

            #picto_animation_white {
                opacity: .3;
            }

            #picto_animation {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: auto;
                height: auto;
        
                path {
                    stroke: $white;
                    stroke-dasharray: 130;
                    stroke-dashoffset: 131;
                    transition: all 1.7s;
                }
            }

            @media screen and (min-width :  1099px){
                #picto_animation_white, #picto_animation {
                    min-width: 250px;
                }
            }

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                #picto_animation_white, #picto_animation {
                    width: 200px;
                    height: 200px;
                    margin: auto;
                    transition: opacity 1s;
                }

                #picto_animation {
                    opacity: 0;
                }
            }
        }
        &:hover {
            .block-img {

                #picto_animation {
                    path {
                        stroke-dashoffset: 0 !important;
                    }

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        opacity: 1;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1024px) and (min-width: 992px){
        padding: 40px 15px;
    }

    @media screen and (max-width: 767px){
        height: auto;
        margin-top: 10px;
        padding: 0 15px;
        max-height: 58px;
        overflow: hidden;
        transition: all .5s;

        .title {
            position: relative;
            left: 0;
            right: 0;
            padding: 18px 10px 18px 0;
            max-width: none;
            top: 0;
            bottom: 0;

            &:after {
                content: "";
                width: 11px;
                height: 7px;
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                background: url(../images/svg/icon-expand-arrow.svg) no-repeat center;
                background-size: 100% 100%;
                transition: transform .5s;
            }

            a {
                font-size: 18px;
                line-height: 22px;
                padding: 0;

                &:before {
                    display: none;
                }
            }
        }

        .detail {
            max-height: none;
            max-width: none;
            padding-bottom: 10px;
			height: auto !important;

            li {
                padding: 12px 0;
                border-bottom: 1px solid $white;
                margin: 0;

                a {
                    padding: 0;

                    &:before {
                        display: none;
                    }
                }

                &:last-of-type {
                    border: none;
                }
            }

            .ps__rail-y {
                opacity: 0;
            }
        }

        &.has-img {
            max-height: none;
            padding: 15px;
        }

        &:hover {
            max-height: 2000px;

            .title {
                transform: none;
                opacity: 1;
            }

        }
    }
}