.block-img-text {
    padding: 50px 0 60px;
	
	&.title-team {
		padding-bottom: 0;
		
		h2 {
			margin-bottom: 0;
		}
	}
    
    h4 {
        line-height: 18px; 
    }

    p {
        &:first-of-type {
            padding: 50px 0 0;
        }

        &:last-of-type {
            padding-bottom: 35px;
        }
    }

    h2 {
        margin-bottom: 50px;
    }

    .img {
        text-align: right;

        img {
            max-width: 300px;
            margin: auto;

            &:not(:nth-of-type(1)) {
                margin-top: 40px;
            }
        }
    }

    &.modele-images-left .img {
        text-align: left;
    }

    @media screen and (max-width: 767px) {
        .img,&.modele-images-left .img {
            text-align: center;
        }

        .img {
            order: 1;
            padding-bottom: 30px;
        }

        .text {
            order: 2;
        }
    }
}