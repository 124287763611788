.multi-image-slider {
    h2 {
        margin-bottom: 50px;
    }

    .items {
        text-align: center;
        max-width: 800px;
        padding-right: 20px;
        float: right;

        &:after {
            content: "";
            display: block;
            clear: both;
        }

        &:not(.owl-carousel) {
            .item {
                img {
                    width: 100%;
                }
            }
        }
    }

    &.right {
        .items {
            padding-right: 0;
            padding-left: 20px;
            float: none;
        }
    }

    .owl-nav {
        top: -20px;
        display: inline-block;
        position: relative;

        button {
            &.owl-prev,&.owl-next  {
                padding: 20px !important;
                background: $voilet;
                position: relative;
                margin-right: 1px; 
                transition: background .5s;

                span {
                    display: none;
                }
                
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 9px;
                    height: 9px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    transform-origin: center;
                    transform: rotate(135deg);
                    border-right: 3px solid $white;
                    border-bottom: 3px solid $white;
                }

                &:hover {
                    background: $blue1;
                }
            }

            &.owl-next  {
                margin: 0;
                margin-left: 1px;

                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
        
    }
}