.block-single-img-text {
    h2 {
        margin-bottom: 50px;
    }

    .block-img {
        max-width: 800px;

        @media screen and (min-width: 768px){
            padding-top: 10px;
        }

        img {
            width: 100%;
        }
    }

    &.left {
        .block-img {
            float: right;
            text-align: right;
            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }
    }
}