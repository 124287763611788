.block-article {
    .filters {
        margin: 0 -10px 70px;

        & > * {
            color: $blue1;
            margin-right: 24px;
            position: relative;

            &:hover,&.active {
                color: $blue2;
            }

            &:after {
                content: "|";
                position: absolute;
                top:0;
                right: -12px;
                color: $voilet;
            }

            &:last-of-type {
                margin: 0;

                &:after {
                    content: "";
                }
            }
        }

        @media screen and (max-width: 767px){
            margin: 0 0 70px;
        }
    }

    .articles {
        & > div {
            padding: 0 5px;

            @media screen and (max-width: 767px){
                padding: 0 15px;

                &:not(:first-of-type){
                    margin-top: 10px;
                }
            }

            a {
                background: $light;
                display: block;
                height: 100%; 
            }

            .img {
                width: 100%;
                
                img {
                    width: 100%;
                }
            }
            
            .content {
                /*background: $light;*/
                padding: 20px;

                h3 {
                    text-transform: uppercase;
                    padding-bottom: 15px;
                    margin-bottom: 20px;
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 5px;
                        width: 30px;
                        background: $blue1;
                        border-radius: 5px;
                    }
                }
            }
        }

        &:not(:first-of-type){
            margin-top: 10px;

            @media screen and (max-width: 767px){
                margin-top: 10px;
            }
        }
    }

    .btn-more {
        margin-top: 50px;

        a {
            width: 43px;
            height: 43px;
            margin: 0 auto;
            background: $light;
            border-radius: 50px;
            display: block;
            position: relative;
            transition: background .3s;

            &:before,&:after {
                content: "";
                position: absolute;
                margin: auto;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: $voilet;
                transition: background .3s;
            }

            &:before {
                width: 20px;
                height: 2px;
            }

            &:after {
                height: 20px;
                width: 2px;
            }

            &:hover {
                background: $voilet;

                &:before,&:after {
                    background: $white;
                }
            }
        }
    }
}