.multi-image {
    overflow: hidden;
    h2 {
        margin-bottom: 60px;
    }

    .block-content {
        padding-top: 50px;
    }

    .block {
        width: 50%;
        position: relative;
        max-width: 800px;
        margin-bottom: 30px;

        &:after {
            content: "";
            display: block;
            clear: both;
        }

        .img {
            position: absolute;
            max-width: 800px;

            .img1 {
                max-width: 75%;
            }
        }
    }
    &.left {
        .block {
            left: 0;
            padding-right: 40px;
            float: left;

            .img {
                right: 40px;
                padding-left: 40%;

                .img1 {
                    position: absolute;
                    left: 0;
                    top: -50px;
                    z-index: -1;
                    right: 50%;
                    max-width: 50%;
                }

                .img2 {
                    text-align: right;
                    margin-left: auto;
                }
            }
        }
    }

    &.right {
        .block {
            right: 0;
            padding-left: 40px;
            float: right;
            
            .img {
                left: 40px;
                padding-right: 40%;

                .img1 {
                    position: absolute;
                    right: 0;
                    top: -50px;
                    z-index: -1;
                    left: 50%;
                    max-width: 50%;
                }
            }
        }
    }
    
}