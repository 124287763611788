.block-contact {
    padding: 0;
    overflow: hidden;

    @media screen and(max-width: 767px){ 
        .container {
            max-width: none;
        }
    }

    h2 {
        margin-bottom: 50px;
    }

    .map {
        position: relative;
        min-height: 500px;

        @media screen and(max-width: 767px){ 
            min-height: 300px;
        }

        #map {
            position: absolute;
            top: 0;
            bottom: -15px;
            right: 0;
            margin-bottom: 0;
            width: 50vw;

            img {
                width: 100%;
            }

            @media screen and(max-width: 767px){ 
                width: 100vw !important;

                max-height: 300px;
                overflow: hidden;
            }

            h2 {
                text-transform: none;
                margin-bottom: 15px;
            }

            p {
                font-size: 16px;
                line-height: 25px;
            }
        }
    }

    .info {
        padding: 50px 0;
        padding-left: 50px;
        display: flex;
        align-items: center;

        & > div > * {
            margin-bottom: 30px;

            &:last-of-type {
                margin: 0;
            }

            a {
                color: inherit;
                font-weight: 400;
            }
        }

        @media screen and(max-width: 767px){
            padding-left: 15px;
        }
    }
}

.block-form {
    h2 {
        margin-bottom: 50px;
    }

    form {
        max-width: 650px;
        margin: auto;

        .input {
            width: 50%;
            display: inline-block;
            float: left;
            padding-bottom: 10px;
            position: relative;

            &:nth-child(even){
                padding-right: 5px;
            }

            &:nth-child(odd){
                padding-left: 5px;
            }

            &.full {
                width: 100%;
                padding: 0;
                max-width: 100%;
                height: 225px;
                padding: 0;

                & > * {
                    height: 100%;
                }
            }
        }

        &:after {
            content: "";
            display: block;
            clear: both;
        }

        .input-btn {
            text-align: center;
            padding-top: 50px;
            clear: both;

            button {
                min-width: 209px;
            }
        }
        
        div.wpcf7-mail-sent-ok {
            display: none !important;
        }

        @media screen and (max-width: 600px){
            max-width: 650px;
            .input {
                width: 100%;

                &:nth-child(odd){
                    padding-left: 0;
                }
    
                &:nth-child(even){
                    padding-right: 0;
                }
            }

        }

        .wpcf7-validation-errors {
            border: none;
            font-family: $font;
            font-size: 16px;
            line-height: 25px;
            padding: 0;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

input, textarea {
    outline: none;
    border: none;
    font-size: 16px;
    line-height: 35px;
    width: 100%;
    background: $white;
    padding: 10px 25px;
    resize: none;
    font-family: $font;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    min-height: 55px;
    height: 100%;

    & + * {
        display: none !important;
    }

    &.wpcf7-not-valid { 
        border-bottom: 2px solid red;
    }
}

input::placeholder, textarea::placeholder {
    font-family: $font;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}