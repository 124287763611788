#footer {

    background: $voilet;

    .block-contact {
        background: #172789;
        padding: 23px 0;

        p {
            font-size: 26px;
            line-height: 34px;
            color: $white;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: -0.3px;
            word-spacing: -2px;

            a {
                font-size: 26px;
                line-height: 33px;
                text-transform: uppercase;
                font-weight: 800;
            }
        }
    }

    .inner-content {
        padding: 60px 0 95px;

        h5 {
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            color: $white;
            text-transform: uppercase;
            padding-bottom: 10px;
            margin-bottom: 20px;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                height: 4px;
                width: 24px;
                left: 0;
                border-bottom: 4px solid $blue2;
                border-radius: 5px;
            }
        }

        p {
            color: $white;

            a {
                color: inherit;
                font-weight: 400;

                &:hover {
                    color: $blue2;
                }
            }
        }

        .contact {
            padding-top: 30px;
        }

        .menu {
            a {
                color: $white;
                position: relative;
                display: block;
                font-size: 20px;
                font-weight: 400;
                line-height: 20px;
                padding-left: 28px;
                margin-bottom: 25px;

                &:not(.has-logo):after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 2px;
                    width: 14px;
                    height: 2px;
                    border-bottom: 2px solid $blue2;
                }
                &:hover {
                    color: $blue2;
                }

                &.has-logo {
                    margin: 107px 0 0;
                    padding: 0;
                }
            }

            li:last-of-type a {
                margin: 0;
            }
        }
        
        .block-adress {
            padding-left: 65px;
        }

        .block-menu {
            padding-left: 125px;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                left: 62px;
                width: 1px;
                border-left: 1px solid $blue2;
                top: 0;
                bottom: 0;
            }
        }
    }

    .copyright {
        padding-bottom: 35px;

        p {
            color: $white;
            text-align: center;
            font-size: 16px;
            line-height: 25px;

            a {
                color: inherit;
                font-weight: 400;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .inner-content {
            .block-adress {
                padding-left: 30px;
            }

            .block-menu {
                padding-left: 60px;

                &:after {
                    left: 30px;
                }
            }
        } 
    }

    @media screen and (max-width: 767px) {

        .block-contact {
            p.center {
				font-size: 20px;
				line-height: 25px;
				
				a {
					font-size: 20px;
					line-height: 25px;
				}
            }
        }
        
        .inner-content {
            .block-map {
                text-align: center;
            }

            .block-adress {
                padding-left: 15px;
                padding-top: 50px;
            }

            .block-menu {
                padding-left: 15px;
                padding-top: 50px;

                &:after {
                    display: none;
                }
                .menu a.has-logo {
                    text-align: center;
                }
            }
        } 
    }
}