#nav {
    background: $white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    //height: 77px;

    .menu-contact {
        text-align: right;
        padding: 15px 15px 0;
        max-height: 100px;
        overflow: hidden;
        transition: all .5s;

        .inner-contact {
            display: inline-block;
            position: relative;
            padding-left: 30px;

            a {
                font-weight: 800;
                text-transform: uppercase;
                font-size: 16px;
                line-height: 40px;
                color: #31619a;
                padding-left: 10px;

                &:hover {
                    color: $blue2;
                }
            }

            .logo {
                width: 28px;
                height: 28px;
                left: 0;
                bottom: 15px;
                right: auto;
                margin: auto;
                min-width: 0;;
                
                .wright-svg {
                    position: absolute;
                    left: 0;
                }

                .wright-svg, .st0 {
                    fill: #31619a !important;
                    stroke: #31619a !important;
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }

    .inner-content {
        height: 100%;
        max-width: 1440px;
        padding: 0 15px;
        margin: auto;

        .btn-close {
            display: none;
        }

        @media screen and (max-width: 1440px){
            max-width: 100%;
        }

        .row {
            height: 100%;
        }
    }

    .menu {
        text-align: right;
        position: relative;
        padding : 0 15px;
        overflow: hidden;

        .menu-contact {
            display: none;
        }

        & > ul {
            display: inline-block;
            padding: 5px 0 0;
            position: relative;
            transition: padding .5s;
            display: block;
            overflow: hidden;
    
            li {
                display: inline-block;
                position: relative;
                padding: 15px 0 25px 38px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: -10px;
                    height: 1px;
                    border-bottom: 1px solid #31619a;
                    opacity: 1;
                    transition: .5s;

                    @media screen and (max-width: 767px){
                        opacity: 0;
                    }
                }
    
                a {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 30px;
                    color: $voilet;
                    
                }
    
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 18px;
                    left: 35px;
                    width: 0;
                    border-bottom: 3px solid $blue1;
                    transition: width .5s;
                }
    
                &:first-of-type {
                    padding-left: 0;
    
                    &:after {
                        left: 0;
                    }
                }
    
                &:hover,&.active {
                    &:after {
                        width: 20px;
                    }
                }
    
                &:hover {
                    .sous-menu {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
    
                &.lang {
    
                    a {
                        position: relative;
                        float: left;
    
                        &:first-of-type {
                            padding-left: 15px;
    
                            &:after {
                                content: "";
                                position: absolute;
                                left: -14px;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                height: 15px;
                                border: none;
                                width: 1px;
                                background: $voilet;
                            }
                        }
    
                        &:last-of-type {
                            padding-left: 14px;
                            position: relative;
    
                            &:after {
                                content: "";
                                position: absolute;
                                left: 7px;
                                top: 0;
                                bottom: 0;
                                height: 15px;
                                width: 1px;
                                background: $voilet;
                                margin: auto;
                            }
                        }
                    }
    
                    a.active, a:hover {
                        color: $blue1;
                        transition: color .5s;
                    }
    
                    &:after {
                        content: "";
                        display: block;
                        clear: both;
                        opacity: 0;
                    }
                }
            }
    
            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }

        .logo-contact {
            position: absolute;
            right: 0;
            top: 5px;
            bottom: 5px;
            overflow: hidden;
            display: block;
            width: 2*$width/12;
            transform: translateX(100%);
            transition: transform .5s;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 0;

                svg {
                    max-width: 100%;
                    width: auto;
                    height: 60px;

                    .st0 {
                        stroke: transparent;
                    }
                }
            }
        }
    }

    .logo {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 30px;
        right: auto;
        height: 70px;

        a {
            height: 100%;
            position: relative;
        }
        
        .wright-svg {
            width: auto;
            height: 65px;
            max-width: 230px;
            transition: all .3s .2s;

            &.has-scroll {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                height: 55px;
                transition: all .3s;
            }
        }
        
    }

    .sous-menu {
        position: fixed;
        top: 129px;
        background: #172789;
        left: 0;
        right: 0;
        text-align: left;
        max-height: 100vh;
        max-height: calc(100vh - 74px);
        overflow: auto;
        opacity: 0;
        visibility: hidden;
        transform: translateY(50px);
        transition: all .5s;

        .container {
            padding: 60px 15px;

            .col-md-4 {
                max-width: 360px;
            }

            .col-md-3 {
                max-width: 240px;
            }

            .item {

                .item-content {
                    display: none;
                }

                h5 {
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 28px;
                    color: $white;
                    padding-bottom: 10px;
                    margin-bottom: 25px;
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 4px;
                        width: 23px;
                        border-bottom: 4px solid $blue2;
                        border-radius: 5px;
                    }

                    &.active {
                        color: $blue2;
                    }

                }

                a {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 25px;
                    color: $white;
                    display: block;
                    margin-bottom: 20px;

                    &:hover,&.active {
                        color: $blue2;
                    }

                    &:last-of-type {
                        margin: 0;
                    }
                }

                &:not(:first-of-type){
                    margin-top: 55px;
                }
            }
        }

        @media screen and (max-width: 1250px) {
            position: relative;
            top: 0;
            opacity: 1;
            visibility: visible;
            max-height: 0;
            overflow: hidden;
            overflow-y: auto;

            .container {
                max-width: 100%;
                padding: 15px 0 0;

                .col-md-4,.col-md-3 {
                    max-width: 100%;
                    margin: 20px 0 0;
                    flex: none;
                }

                .col-md-4:first-of-type {
                    margin-top: 0;
                }

                .item {
                    padding: 0 15px;
                    transition: all .3s;

                    &:not(:first-of-type) {
                        margin-top: 20px;
                    }

                    h5 {
                        padding-bottom: 0;
                        padding-left: 30px;
                        margin-bottom: 0;
                        cursor: pointer;

                        &:after {
                            top: 0;
                            margin: auto;
                        }
                    }

                    & > h5 {
                        font-size: 18px;
                        line-height: 22px;
                        font-weight: 400;
                    }

                    & > a {
                        display: none;
                    }

                    a {
                        padding-left: 30px;
                    }

                    .item-content {
                        display: block;
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        background: #172789;
                        bottom: 0;
                        max-height: 100vh;
                        max-height: calc(100vh - 77px);
                        overflow: hidden;
                        z-index: 1;
                        padding: 30px 15px;
                        transform: translateX(-50%);
                        visibility: hidden;
                        opacity: 0;
                        transition: all .3s;

                        h5 {
                            text-align: center;
                            text-transform: uppercase;
                            padding: 0 30px 10px;
                            margin-bottom: 35px;

                            &:after {
                                bottom: 0;
                                right: 0;
                                top: auto;
                            }
                        }

                        .btn-back {
                            position: absolute;
                            width: 40px;
                            height: 38px;
                            top: 25px;
                            left: 15px;
                            cursor: pointer;

                            &:after {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                margin: auto;
                                width: 15px;
                                height: 15px;
                                border-left: 3px solid $white;
                                border-bottom: 3px solid $white;
                                transform: rotate(45deg);
                            }
                        }

                        a {
                            padding: 0;
                        }

                        &.open {
                            transform: translateX(0);
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        height: 77px;

        .inner-content {
            .logo {
                .wright-svg {
                    opacity: 0;
                    visibility: hidden;
    
                    &.has-scroll {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            
            .menu-contact {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                max-width: 100%;
                flex: 0 0 100%;
                padding: 0;
                display: none;
                padding: 5px 0;
    
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 1px;
                    border-bottom: 1px solid #31619a;
                    opacity: 1;
                    -webkit-transition: .5s;
                    transition: .5s;
                }
    
                .inner-contact {
                    .logo {
                        bottom: 0;
                    }
    
                    a {
                        color: $white;
                    }
                    
                    .logo {
                        bottom: 0;
    
                        .wright-svg {
                            opacity: 1;
                            visibility: visible;
                        } 
        
                        .st0 {
                            fill: $white !important;
                            stroke: $white !important;
                        }
                    }
                }
            }
            
            .menu-content {
                position: fixed;
                top: 77px;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 0 0;
                max-width: none;
                text-align: left;
                background: #172789;
                height: auto;
                margin: 0;
                transform: translateX(-100%);
                opacity: 0;
                visibility: hidden;
                transition: all .5s;
                overflow: hidden;
                overflow-y: auto;
    
                .menu {
                    text-align: left;
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding: 30px 15px 70px;
                    min-height: 100%;

                    &.nav3-open {
                        overflow: hidden;
                        max-height: 100%;
                    }
    
                    ul {
                        display: block;
                        padding: 0 15px;
    
                        li {
                            display: block;
                            float: none;
                            padding: 0 0 20px;
    
                            a {
                                color: $white;
                            }
    
                            &:after {
                                top: 33px;
                                bottom: auto;
                            }
    
                            &:first-of-type {
                                padding-left: 0;
                            }
    
                            &:after {
                                left: 0;
                                border-bottom-color: $blue2;
                            }
    
                            &.lang {
                                a {
                                    float: none;
    
                                    &:first-of-type {
                                        padding: 0;
                                    }
                                    
                                    &:after {
                                        background: transparent;
                                    }
    
                                    &:not(:first-of-type):after {
                                        background: $blue2;
                                    }
    
                                    &:hover,&.active {
                                        color: $blue2;
                                    }
    
                                } 
                            }
    
                            .sous-menu {
                                transform: none;
                            }
    
                            &:hover {
                                .sous-menu {
                                    max-height: 1000px;
                                }
                            }
                        }
                    }
    
                    &> ul:before {
                        display: none;
                    }
    
                    .logo-contact {
                        display: none;
                    }
    
                    .menu-contact {
                        display: block;
                    }
                }
            }
    
            .btn-close {
                display: block;
                position: absolute;
                top: 50%;
                right: 30px;
                width: 35px;
                height: 35px;
                transform: translateY(-50%);
                cursor: pointer;
    
                span {
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    left: 5px;
                    border-bottom: 3px solid $voilet;
                    transform: translateY(-50%);
                    cursor: pointer;
                    transition: border-bottom-color 0s .2s;
    
                    &:before,&:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        left: 0;
                        border-bottom: 3px solid $voilet;
                        transition: top .2s .2s, transform .2s;
                    }
    
                    &:before {
                        top: -8px;
                    }
    
                    &:after {
                        top: 8px;
                    }
                }
    
                @media screen and(max-width: 767px) {
                    right: 15px;
                }
    
            }
        }

        &.menu-open {
            .btn-close {
                span {
                    border-bottom-color: transparent;
    
                    &::before, &::after {
                        top: 0;
                        transition: top .2s, transform .2s .2s;
                    }
    
                    &::before {
    
                        transform: rotate(45deg);
                    }
    
                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }
    
            .menu-content {
                transform: translateX(0);
                opacity: 1;
                visibility: visible;
            }
        }
    }

    @media screen and (max-width: 767px){
        .logo {
            left: 15px;
        }
    }

    &.fixed {
        //height: 77px;
        
        .menu-contact {
            max-height: 0;
            padding: 0 15px;
        }

        .menu {
            & > ul{
                padding-right: 2*$width/12;

                li:before {
                    opacity: 0;
                }
            }

            .logo-contact {
                transform: none;
            }
        }

        .sous-menu {
            top: 75px;
        }

        .logo {

            .wright-svg {
                opacity: 0;
                visibility: hidden;
                transition: all .3s;

                &.has-scroll {
                    opacity: 1;
                    visibility: visible;
                    transition: all .3s .2s;
                }
            }
        }
    }
}

.admin-bar {
    #nav {
        top: 32px;

        .sous-menu {
            top: 160px;

            @media screen and (max-width: 1250px){
                top: 0;
            }
        }

        &.fixed {
            .sous-menu {
                top: 105px;

                @media screen and (max-width: 1250px){
                    top: 0;
                }
            }
        }

        @media screen and (max-width: 1250px){
            .inner-content .menu-content {
                top: 108px;
            }

            &.fixed {
                .inner-content .menu-content {
                    top: 77px;
                }
            }
        }

        @media screen and (max-width: 782px){
            top: 46px;

            .inner-content .menu-content {
                top: 122px;
            }

            &.fixed {
                top: 0;

                .inner-content .menu-content {
                    top: 77px;
                }
            }
        }
    }


}