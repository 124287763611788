.block-services {
    h2 {
        margin-bottom: 50px;
    }

    .service {
        padding: 7px;
        
        .content {
            padding: 20px 30px 125px 40px;
            height: 100%;
            &.voilet {
                background: $voilet;

                .has-border:hover {
                    color: $voilet;
                }
            }
    
            &.voilet-bg {
                background: $voilet-bg;

                .has-border:hover {
                    color: $voilet-bg;
                }
            }
    
            &.blue {
                background: $blue1;

                .has-border:hover {
                    color: $blue1;
                }
            }

            h2 {
                color: $white;
                font-size: 28px;
                line-height: 44px;
                padding-bottom: 2px;
                display: block;
                margin: 0 15px 30px;

                &:after {
                    background: $blue2;
                }
            }

            p {
                color: $white;
            }

            .title {
                text-align: center;
            }

            a {
                font-weight: 500;
                font-size: 18px;
                line-height: 60px;
                border: 3px solid $blue2;
                color: $blue2;
                display: inline-block;
                text-transform: uppercase;
                padding: 0 27px;
                transition: all .5s;
                outline: none;
                background: initial;
                cursor: pointer;
                color: $white;
                border: 3px solid $white;
                background: $voilet;
                position: relative;
        
                &:before {
                    content: "";
                    position: absolute;
                    top: -17px;
                    bottom: -17px;
                    left: -16px;
                    right: -16px;
                    border-left: 13px solid $voilet; 
                    border-right: 13px solid $voilet; 
                    border-top: 14px solid $voilet; 
                    border-bottom: 14px solid $voilet; 
                }
            
                &:hover {
                    background: $white;
                    color: $voilet;
                }

                position: absolute;
                bottom: 40px;
                text-align: center;
                background: transparent;
                
                &:before {
                    display: none;
                }
                &:hover {
                    background: $white;
                }
            }
        }
    }
	
	@media screen and (max-width: 1199px){
		.services {
			padding: 0;
			margin: 0;
			max-width: 100%;
			flex: 1 1 100%;
		}
	}

    @media screen and (max-width: 991px){
        .service {
			padding: 7px 0;
			
            .content {
                padding: 20px 15px 125px;
                
                h2 {
                    margin: 0 0 30px;
                }
            }
        } 
    }
}