
/*   DIN   */

@font-face {
    font-family: 'Raleway';
    src: url('fonts/Raleway-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('fonts/Raleway-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('fonts/Raleway-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('fonts/Raleway-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('fonts/Raleway-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('fonts/Raleway-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}