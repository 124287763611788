@import "box";

.services {
    //max-width: 1440px;
    padding: 0 15px;

    .col-lg-3 {
        padding: 0;
        
        @media screen and (max-width: 767px){
            padding: 0 15px;
        }
    }

    @media screen and (max-width: 991px) { 
        margin: auto;
    }
}
