.temoignages {
    .inner-content {
        padding: 0 0 95px;

        .block {
            position: relative;
            padding: 70px 65px;
        }
        .items {
            background: $light;
        }
        .item {
            
            padding: 45px 40px;
            p {
                text-align: center;
                word-spacing: -1.5px;
            }
        }

        .icon-left,
        .icon-right {
            position: absolute;
            width: 100px;
        }
        .icon-left {
            left: 0;
            bottom: 20px;
        }

        .icon-right {
            right: 0;
            top: 20px;
        }
        .owl-nav {
            display: none;
        }

        .owl-dots {
            text-align: center;
            margin-top: 90px;
            position: absolute;
            left: 0;
            right: 0;
            
            .owl-dot {
                display: inline-block;
                height: 19px;
                width: 19px;
                border: 1px solid $voilet;
                margin: 0 7px;
                border-radius: 50%;
                transition: background .5s;
                cursor: pointer;

                &:hover, &.active {
                    background: $voilet;
                }
            }
        }

        @media screen and (max-width: 530px) {
            .block {
                padding: 70px 35px;
            }
            
            .item {
                padding: 45px 20px;
            }

            .icon-left,
            .icon-right {
                width: 50px;
                height: 50px;
            }

            .icon-left {
                bottom: 30px;
            }
    
            .icon-right {
                top: 30px;
            }
        }
    }

    @media screen and (max-width: 767px){
        .container {
            max-width: 100%;

            .inner-content {
                padding-bottom: 20px;

                .block {
                    padding: 50px 25px;

                    .icon-left, .icon-right {
                        width: 60px;
                    }
                    .icon-left {
                        bottom: 35px;
                    }
    
                    .icon-right {
                        top: 35px;
                    }
                    
                    .item {
                        padding: 45px 25px;
                    }
                    
                    .owl-dots {
                        margin-top: 30px;
                    }

                    .owl-nav {
                        display: block;

                        .owl-prev,.owl-next {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            width: 20px;
                            height: 20px;
                            margin: auto;

                            span {
                                display: none;
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                border-bottom: 2px solid $voilet;
                            }
                            
                        }

                        .owl-prev {
                            left: -25px;

                            &:after {
                                border-left: 2px solid $voilet;
                                transform: rotate(45deg);
                            }
                        }

                        .owl-next {
                            right: -25px;

                            &:after {
                                border-right: 2px solid $voilet;
                                transform: rotate(-45deg);
                            }
                        } 
                    }
                }
            }
        }
    }
}