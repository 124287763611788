.block-slider {
    padding: 50px 0 60px;

    .inner-content {
        margin-top: 60px;
        position: relative;
        width: 100%;

        .col-md-9 {
            padding: 0 9px;

            @media screen and (max-width: 991px) { 
                margin: 0;
                max-width: 100%;
            }
        }

        .items {
            .owl-item {
                opacity: 0;
                visibility: hidden;
                transform: translateX(-50px);
                transition: all .25s;

                &.active {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                }
            }

            .item {
                width: 100%;
                position: relative;

                img {
                    position: relative;
                    width: 100%;
                    float: left;
                }

                &:after {
                    content: "";
                    display: block;
                    clear: both;
                }
            }
        }

        .info {
            position: absolute;
            left: 0;
            max-width: 300px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            background: $green;
            padding: 25px 35px;

            p {
                color: $white;
            }

            @media screen and (max-width: 991px) {
                position: relative;
                margin-top: -100px;
                max-width: none;
                transform: none;
                top: 0;
                margin-left: 30px;
                margin-right: 30px;
            }
        }
    }
	
	@media screen and (max-width: 767px){
		.container {
			.row {
				margin: 0;
				
				.inner-content {
					.col-md-9 {
						padding: 0;
					}
					
					.info {
						margin-left: 15px;
						margin-right: 15px;
						padding: 15px;
					}
				}
				
			}
		}
	}
    
}