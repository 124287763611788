.partenaires {
    background: $light;

    .inner-content {
        padding: 70px 0 55px;

        @media screen and (max-width: 767px){
            &:not(.owl-carousel) {
                .item {
                    text-align: center;
                    padding: 25px 0;

                    &:first-of-type {
                        padding-top: 0;
                    }

                    &:last-of-type {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    .owl-carousel .owl-item {
        .items {
            display: flex;
        }

        .item {
            flex: 1 1 25%;
            max-width: 25%;
            text-align: center;
            padding: 0 15px;

            img {
                width: auto;
                height: 60px;
        
                @media screen and (max-width: 889px) {
                    margin: auto;
                }
            }
        }
    }
}