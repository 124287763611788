#header {
	margin-top: 130px;
    min-height: 510px;
    position: relative;

    h1 {
        font-weight: 500;
        font-size: 36px;
        line-height: 55px;
        max-width: 600px;
        margin-bottom: 45px;
        color: $white;

        strong {
            color: $blue2;
            font-weight: 500;
            font-size: 36px;
            line-height: 55px;
        }

        &:after {
            display: none;
        }
    }

    & > h1 {
        @media screen and (max-width: 599px) {
            padding: 0 15px;
        }
    }

    a {
        font-weight: 500;
        font-size: 18px;
        line-height: 60px;
        border: 3px solid $blue2;
        color: $blue2;
        display: inline-block;
        text-transform: uppercase;
        padding: 0 27px;
        transition: all .5s;
        outline: none;
        background: initial;
        cursor: pointer;
        min-width: 209px;
    
        &:hover {
            color: $white;
            background: $blue2;
        }
    }

    .item {
        min-height: 510px;
        padding-top: 155px;
        position: relative;
        background-position: center 60%;
        background-size: 100% auto;
        background-repeat: no-repeat;
        z-index: 0;

        .inner-content {
            opacity: 0;
            transition: opacity .5s .5s;
        }

		@media screen and (max-width : 1250px) {
			background-size: cover;
		}
		
        @media screen and (max-width : 767px) {
            padding-bottom: 100px;
			background-position: center;

            h1 {
                div {
                    display: inline-block;
                }
            }
        }
    }



    &.owl-carousel {

        .owl-stage {
            transition-duration: .5s !important;
        }

        .owl-item.active {
            .inner-content {
                opacity: 1;
            }
        }
        .owl-dots {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 45px;
            text-align: center;
            
            .owl-dot {
                display: inline-block;
                height: 14px;
                width: 14px;
                border: 1px solid $white;
                margin: 0 7px;
                border-radius: 50%;
                transition: background .5s;
                cursor: pointer;

                &:hover, &.active {
                    background: $white;
                }

                span {
                    display: none;
                }
            }
        }
    }
	
	@media screen and (max-width: 1250px) {
        margin-top: 76px;
    }

    @supports (-ms-ime-align: auto) {
        
    }
}

body.seconder {
    #header {
        margin-top: 130px;
        min-height: 195px;
        background-size: cover;
        background-position: bottom;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;

        .content {
            text-align: center;
            margin: auto;
            padding: 0 15px;

            p {
                color: $white;
            }
        }

        h1 {
            text-align: center;
            margin: auto;
        }

        @media screen and (max-width: 1250px) {
            margin-top: 76px;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            padding: 80px 0;
        }
    }
}