.examens {
    .category {
        width: 100%;
        text-align: center;

        a {
            display: inline-block;
            font-family: $font;
            font-size: 20px;
            line-height: 30px;
            font-weight: 400;
            color: $voilet;
            position: relative;
            padding: 15px 18px 20px ;
            cursor: pointer;

            &:after {
                content: "";
                position: absolute;
                bottom: 13px;
                left: 18px;
                width: 0;
                border-bottom: 3px solid $blue1;
                transition: width .5s;
            }

            &:hover,&.active {

                &:after {
                    width: 20px;
                }
            }
        }
    }

    .btn_backTop {
        background: $blue1;
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 15px;
        right: 15px;
        border-radius: 50px;
        transform: translateY(50px);
        opacity: 0;
        visibility: hidden;
        transition: all .5s;
        cursor: pointer;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 45%;
            height: 45%;
            margin: auto;
            border-top: 3px solid $white;
            border-right: 3px solid $white;
            transform: translateY(25%) rotate(-45deg);
            transform-origin: center;
        }

        &.show {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
        }

        &:hover {
            background: $voilet-bg;
        }
    }
}